<template lang="pug">
  .rate-plan-details-tabs
    ArrowBack(@back="$emit('back')")
    AppOverlayLoader(:state="loading")
    BTabs(
      fill
      vertical
      nav-wrapper-class="tabs-details-list"
      :value="tabIndex"
    )
      BTab(
        v-for="tab in tabs"
        :disabled="isTabDisabled(tab.id)"
        :title="tab.title"
        :title-item-class="`tab-id-${tab.id}`"
        :key="tab.id"
        @click="handleTabSelected(tab)"
      )
        BasicSettingsForm(
          v-if="tab.id ==='basic_settings'"
          :plan-id="planId"
          @remove-plan="removePlan"
        )
        CommonSettingsForm(
          v-if="tab.id ==='common_settings' && !isTabDisabled(tab.id)"
          :plan-id="existingPlanId"
          @remove-plan="removePlan"
          @refresh-plan="refreshPlan"
        )
        CarClassSettingsForm(
          v-if="tab.id ==='car_class_settings' && !isTabDisabled(tab.id)"
          :plan-id="existingPlanId"
          @remove-plan="removePlan"
          @refresh-plan="refreshPlan"
        )
</template>

<script>
  import { findIndex } from "lodash-es"

  // mixins
  import withConfirmation from "@/mixins/withConfirmation"
  import withStoreModule from "@/mixins/withStoreModule"

  // stores
  import ratePlanModule from "@/config/store/company_system/rate_plan_settings/rate_plan/basic_settings"
  import planCommonModule from "@/config/store/company_system/rate_plan_settings/rate_plan/common_settings"
  import priceSettingsModule from "@/config/store/company_system/rate_plan_settings/rate_plan/prices_settings"

  const ratePlanMixin = withStoreModule(ratePlanModule, {
    name: "ratePlan",
    readers: {
      plan: "item",
      planLoading: "loading"
    },
    actions: {
      fetchPlan: "FETCH_ITEM",
      deletePlan: "DELETE_ITEM"
    }
  })

  const planCommonSettingMixin = withStoreModule(planCommonModule, {
    name: "companiesPlanCommonSetting",
    readers: { commonSettingLoading: "loading" }
  })

  const pricesSettingMixin = withStoreModule(priceSettingsModule, {
    name: "planPricesSettingSetting",
    readers: { pricesSettingLoading: "loading" }
  })

  export default {
    components: {
      ArrowBack: () => import("@/components/CompanySystem/ArrowBack"),
      BasicSettingsForm: () => import("./BasicSettingsForm"),
      CommonSettingsForm: () => import("./CommonSettingsForm"),
      CarClassSettingsForm: () => import("./CarClassSettingsForm"),
      AppOverlayLoader: () => import("@/components/elements/AppOverlayLoader")
    },

    mixins: [withConfirmation, ratePlanMixin, planCommonSettingMixin, pricesSettingMixin],

    props: {
      planId: {
        type: Number,
        required: false
      }
    },

    data() {
      return {
        selectedTab: {}
      }
    },

    mounted() {
      this.selectedTab = this.basicSettingsTab
    },

    computed: {
      loading() {
        return this.planLoading || this.commonSettingLoading || this.pricesSettingLoading
      },

      existingPlanId() {
        return this.planId || this.plan.id
      },

      isNewPlan() {
        return !this.existingPlanId
      },

      tabIndex() {
        return findIndex(this.tabs, { id: this.selectedTab.id })
      },

      basicSettingsTab() {
        return {
          id: "basic_settings",
          title: this.$t("company_system.rate_plan_settings.rate_plan.details.basic_settings.title")
        }
      },

      tabs() {
        return [
          this.basicSettingsTab,
          {
            id: "common_settings",
            title: this.$t("company_system.rate_plan_settings.rate_plan.details.common_settings.title")
          },
          {
            id: "car_class_settings",
            title: this.$t("company_system.rate_plan_settings.rate_plan.details.car_class_settings.title")
          }
        ]
      }
    },

    methods: {
      handleTabSelected(tab) {
        this.selectedTab = tab
      },

      isTabDisabled(tabId) {
        return tabId !== "basic_settings" && this.isNewPlan
      },

      refreshPlan() {
        this.fetchPlan(this.existingPlanId)
      },

      removePlan() {
        this.$confirm({
          title: this.$t("company_system.rate_plan_settings.delete_plan_confirmation"),
          resolve: {
            handler: () => {
              this.deletePlan(this.planId)
              this.$emit("back")
            }
          }
        })
      }
    }
  }
</script>

<style lang="sass" scoped>
  @import "@/assets/styles/variables.sass"

  .rate-plan-details-tabs
    position: relative

    .arrow-back
      cursor: pointer
      margin-left: 5px
      margin-top: 10px

      ::v-deep
        svg
          path
            fill: $default-gray-medium

      span
        vertical-align: text-top
        margin-left: 5px
        color: $default-purple
        font-size: 0.9rem

    .tabs
      margin-top: 10px
      flex-wrap: unset
</style>
