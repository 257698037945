import { api, i18n } from "@/config"
import { handleFailedResponse } from "@/helpers/common"
import { showToast } from "@/helpers/toasts"

export default ({ baseURI }) => ({
  FETCH_ITEM: async ({ commit }, planId) => {
    try {
      const { data } = await api.get(`${baseURI}/${planId}/prices_settings`)
      commit("SET_ITEM", data.data)
    } catch (e) {
      handleFailedResponse(e)
    }
  },

  UPDATE_ITEM: async ({ commit }, { planId, ...params }) => {
    try {
      const { data } = await api.post(`${baseURI}/${planId}/prices_settings`, params)
      commit("SET_ITEM", data.data)
      showToast({
        title: i18n.t("success"),
        text: i18n.t("company_system.rate_plan_settings.car_class_setting_updated"),
        variant: "success"
      })
    } catch (e) {
      handleFailedResponse(e)
      throw e
    }
  }
})
